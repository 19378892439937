<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Tiles</h1>
              <div class="pagehead-link"></div>
            </div>
            <p>
              A tile represents a single action or option.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    A tile can be used as a filter option in scenarios where
                    there is a relatively small number of filters or certain
                    filters are prioritied for ease of use.
                  </li>
                  <li>
                    The words on tiles should generally be action oriented
                    (Share, Buy, Download, etc.) and should have no more than 3
                    words with a preference for 1-2. Each word on a button
                    should have the first letter capitalized (camel-case) except
                    the words "to" and "and".
                  </li>
                </ul>
              </div>
            </section>
            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="tile-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="large-tiles">
              <div class="content-band">
                <h3>Large Tiles</h3>
                <CodeView component="tile" type="large-tile-image-active">
                  <TileComponent id="lr-tile-with-image" />
                </CodeView>
                <CodeView component="tile" type="large-tile-icon-active">
                  <TileComponent id="lr-tile-with-icon" />
                </CodeView>
                <CodeView component="tile" type="large-tile-icon-disabled">
                  <TileComponent id="lr-tile-with-icon-image" />
                </CodeView>
              </div>
            </section>
            <section id="small-tiles">
              <div class="content-band">
                <h3>Small Tiles</h3>
                <CodeView component="tile" type="small-tile-image-active">
                  <TileComponent id="small-tile-imgae-active" />
                </CodeView>
                <CodeView component="tile" type="small-tile-icon-active">
                  <TileComponent id="small-tile-icon-active" />
                </CodeView>
                <CodeView component="tile" type="small-tile-icon-disabled">
                  <TileComponent id="small-tiles-icon-disabled" />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../../StickyTOC";
import TileComponent from "../Tiles/TileComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
export default {
  name: "Tiles",
  data: function() {
    return {};
  },
  components: {
    CodeView,
    TileComponent,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
