<template>
  <div class="page-container gray-200">
    <section v-if="id === 'lr-tile-with-image'">
      <div class="content-band flex flex-col max-w1440">
        <div class="container even-cols flex-wrap gap-20">
          <a href="#" class="tile tile--photo white hover-shadow rounded-8">
            <div class="tile_img">
              <img
                class="img-center"
                src="../../../assets/images/tiles/money-flow.jpg"
                alt="money-flow"
              />
            </div>
            <div class="tile_content">
              <header>
                <div class="tile_title">Money Flow</div>
              </header>
              <p>
                The fundamental visual elements that provide structure and
                consistency to state digital experiences. Lorem ipsum dolor set
                amet
              </p>
            </div>
          </a>
        </div>
      </div>
    </section>
    <section v-if="id === 'lr-tile-with-icon'">
      <div class="content-band flex flex-col max-w1440">
        <div class="container even-cols flex-wrap gap-20">
          <div class="tile white gray-300 rounded-8">
            <div class="tile_img">
              <img
                src="../../../assets/images/tiles/iconography-icon.png"
                alt="Foundations Icon"
              />
            </div>
            <div class="tile_content">
              <header>
                <div class="tile_title">Foundations</div>
              </header>
              <p>
                The fundamental visual elements that provide structure and
                consistency to state digital experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="id === 'lr-tile-with-icon-image'">
      <div class="content-band flex flex-col max-w1440">
        <div class="container even-cols flex-wrap gap-20">
          <div class="tile white rounded-8">
            <div class="tile_img circle">
              <img
                class="img-top"
                src="../../../assets/images/tiles/service-offerings.jpg"
                alt="money-flow"
              />
            </div>
            <div class="tile_content">
              <header>
                <div class="tile_title">Tile with circle mask</div>
              </header>
              <p>
                The fundamental visual elements that provide structure and
                consistency to state digital experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-else-if="id === 'small-tile-imgae-active'">
      <div class="content-band flex flex-col max-w1440">
        <div class="container flex-grid gap-20 flex-row-mobile justify-center ">
          <a href="#" class="tile-sml tile-sml--photo white rounded-8">
            <div class="tile-sml__img-container">
              <img
                class="img-top"
                src="../../../assets/images/tiles/service-offerings.jpg"
                alt="money-flow"
              />
            </div>
            <div class="tile__title text-center">Services</div>
          </a>
        </div>
      </div>
    </section>
    <section v-else-if="id === 'small-tile-icon-active'">
      <div class="content-band flex flex-col max-w1440">
        <div class="container flex-grid gap-20 flex-row-mobile justify-center ">
          <a href="#" class="tile-sml white hover-gray-300 rounded-8">
            <div class="tile-sml__img-container">
              <img
                src="../../../assets/images/tiles/typo-icon.png"
                alt="Calandar icon"
              />
            </div>
            <div class="tile__title text-center">Typography</div>
          </a>
        </div>
      </div>
    </section>
    <section v-else-if="id === 'small-tiles-icon-disabled'">
      <div class="content-band flex flex-col max-w1440">
        <div class="container flex-grid gap-20 flex-row-mobile justify-center ">
          <div class="tile-sml white rounded-8 gray-300">
            <div class="tile-sml__img-container">
              <img
                src="../../../assets/images/tiles/progress-dial.png"
                alt="progress-dial icon"
              />
            </div>
            <div class="tile__title text-center">progress-dial</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TileComponent",
  props: ["id"],
};
</script>

<style scoped>
/*/////////////////////////// 
    TILE 
///////////////////////////*/

.tile > * {
  position: relative;
  transition: all 200ms ease-in-out;
}

.tile {
  position: relative;
  flex: 1 0 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: inherit;
  min-height: 200px;
  min-width: 330px;
  padding: 30px 20px;
  color: black;
  overflow: hidden;
  outline: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}
.tile:focus {
  outline: 2px solid black;
}

.tile .tile_img {
  display: flex;
  flex: 0 0 150px;
  align-items: center;
  justify-content: center;
}
.tile .tile_img.circle > img {
  min-height: 150px;
  max-width: 150px;
}

.tile.tile--photo {
  padding: 0 !important;
}

.tile.tile--photo .tile_img {
  display: flex;
  flex: 0 0 30%;
  align-items: center;
  justify-content: flex-start;
  min-height: 140px;
  height: 100%;
  width: 100%;
}

.tile .tile_content {
  flex-basis: 70%;
  display: block;
  padding: 0 20px 0 30px;
  width: inherit;
}
.tile .tile_content > header > .tile_title {
  font-size: 35px;
  line-height: 40px;
  /* margin-bottom: 15px; */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tile .tile_content > p {
  margin-bottom: 0 !important;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tile .tile-btn-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

/*/////////////////////////// 
    TILE - SML
///////////////////////////*/

.tile-xsml {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 170px;
  height: 160px;
}

.tile-sml {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 222px;
  width: 100%;
  min-height: 150px;
  max-height: 200px;
  padding: 20px;
  overflow: hidden;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}
.tile-sml .tile__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 5px 0;
  padding: 0 10px;
  font-size: 16px;
  text-decoration: none;
  color: black;
}

.tile-sml.tile-sml--photo {
  padding: 0;
}
.tile-sml.tile-sml--photo > .tile-sml__img-container {
  width: 100%;
}

.tile-sml .tile-sml__img-container > img {
  object-fit: none;
}

.tile-sml__img-container {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 140px;
}

.tile-xsml .tile-img {
  max-height: 100px;
}
.tile-sml:hover {
  z-index: 2;
}

/* //////////////////////////
    MEDIA QUERIES
//////////////////////// */

@media screen and (min-width: 1200px), (max-width: 1200px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 768px) {
  .tile.placeholder {
    display: none !important;
  }
}

/* Mobile - portrait: 512px and smaller */
@media screen and (max-width: 512px) {
  /* Tile: default */
  .tile {
    flex: auto;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .tile .tile_img {
    flex: auto;
  }
  .tile .tile_content {
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-top: 20px;
  }
  .tile .tile_content > header > .tile_title {
    margin-top: 10px;
    font-size: 30px !important;
    line-height: 34px;
    text-align: center;
  }
  .tile .tile_content > p {
    text-align: center;
  }
  /* with photo */

  .tile.tile--photo {
    flex: unset;
    justify-content: flex-start;
  }

  .tile.tile--photo .tile_img > img {
    width: 100%;
    object-fit: none !important;
  }
  .tile.tile--photo .tile_img {
    display: flex;
    flex: 0 0 60%;
    align-items: center;
    min-height: 140px;
    height: 100%;
    width: 100%;
  }
  .tile .tile-btn-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 20px;
  }

  /* Tile: small */

  .tile-sml {
    max-width: 150px;
    max-height: 150px;
  }
  .tile-sml__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 120px;
    min-height: 0;
    width: 100%;
  }
  .tile-sml .tile-sml__img-container > img {
    object-fit: contain;
    width: 80%;
  }
  .tile-sml .tile__title {
    margin-bottom: 0;
  }

  /* Tile: small with photo */
  .tile-sml.tile-sml--photo {
    justify-content: normal;
  }
  .tile-sml.tile-sml--photo .tile-sml__img-container > img {
    object-fit: contain;
    width: 100%;
  }
  .tile.placeholder {
    display: none !important;
  }
}
</style>
